@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
html,body {
  height: 100%;
}
.app-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .app-not-found__image {
    width: 380px;
    height: 180px;
    background: no-repeat center/cover url("../../assets/404.png");
  }
  .app-not-found__title {
    margin-top: 32px;
    font-size: 32px;
  }
}
